.background{
    background-position: center;
    background-size: cover;
    position: absolute;
    inset: 0;
    overflow: hidden;
}
.comingSoon{
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 21px;
    text-align: center;
}
.emblem{
    stroke: #fff;
    transform: translate(-50%, -50%) scale(5.5);
    position: absolute;
    top: 50%;
    left: 50%;
}
.name{
    font-family: 'copperplate-gothic', sans-serif;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 36px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .emblem{
        transform: translate(-50%, -50%) scale(15.5);
    }
    .name{
        font-size: 21px
    }
}